//定义字体
.index_main{
  display: block;
}
* {
  -webkit-touch-callout: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  margin:0;
  padding:0;
  font-family: '微软雅黑', 'Microsoft YaHei', 'microsoft jhenghei', '微软正黑体', '黑体', 'simhei',
  'Heiti SC Light', 'Heiti SC Medium', 'PingFang SC Medium'!important;
}
a{
  text-decoration: none;
  color:#444;
}

a:focus{
  outline:none;
  color:#444;
}
a:hover{
  text-decoration: underline;
}
body,
html,
.app {
  padding: 0;
  margin: 0;
  background: #fff;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
div {
  font-size: 12px;
}

.color-pink {
  color: #eb2f96;
}

.color-red {
  color: #f5222d;
}

.color-orange {
  color: #fa8c16;
}

.color-green {
  color: #52c41a;
}

.color-cyan {
  color: #13c2c2 !important;
}

.color-blue {
  color: #1890ff !important;
}

.color-purple {
  color: #722ed1 !important;
}

.headerMain{
  .header{
    background: rgba(0, 101, 175, 1);
    height:100px;
    font-size: 14px;
    width:100%;
    padding:0 20px;
    .menuMain{
      display: flex;
      justify-content: space-between;
      max-width:1100px;
      margin:0 auto;
      align-items: center;
      height:100px;
      .imgBox{
        width:100px;
        cursor: pointer;
        img{
          width:100%;
        }
      }
      .menuBox{
        flex: 1;
        display: flex;
        justify-content: space-evenly;
        height:100px;
        .menuItem{
          text-align: right;
          height: 100px;
          line-height: 100px;
          a{
            padding:0 25px;
            display: block;
            transition: all .2s linear;
            -moz-transition: all .2s linear;
            /* Firefox 4 */
            -webkit-transition: all .2s linear;
            /* Safari 和 Chrome */
            -o-transition: all .2s linear;
            h3{
              font-size: 16px;
              font-weight: bold;
              color:#fff;
              display: initial;
            }
            &:hover{
              background: #beeeff;
              text-decoration: none;
              h3{
                color:#0065af;
              }
            }
          }
        }
      }
    }
    &.active{
      position: fixed;
      top:0;
      left:0;
      z-index: 998;
    }
  }
  .header2{
    height:100px;
    width:100%;
    display: none;
    background: transparent;
  }
  .header_h5{
    display: none;
  }
  .toTopBtn{
    position: fixed;
    right:15px;
    display: none;
    bottom:30px;
    cursor: pointer;
    z-index: 998;
  }
}
.footerMain{
  background: rgba(0, 101, 175, 1);
  height:450px;
  width:100%;
  .footerMenuBox{
    max-width:1100px;
    margin:0 auto;
    padding:40px 20px;
    display: flex;
    height:320px;
    justify-content: space-evenly;
    .menuItem{
      width:20%;
      h3{
        width:100%;
        margin-bottom:15px;
        cursor: pointer;
        a{
          color:#fff;
          font-weight: bold;
          font-size: 16px;
          &:hover{
            text-decoration:underline;
          }
        }
      }
      p{
        margin-bottom:10px;
        a{
          color:#fff;
          font-size: 14px;
          width:100%;
          cursor: pointer;
          &:hover{
            text-decoration:underline;
          }
        }
      }
      &:last-child{
        text-align: center;
        margin-top:30px;
        h4{
          color:#fff;
          font-size: 16px;
          font-weight: normal;
          line-height: 1.5;
          margin-top:10px;
        }
      }
    }
  }
  .footerBomBox{
    height:130px;
    width:100%;
    background: rgba(219, 219, 219, 1);
    .btnList{
      width:100%;
      display: flex;
      max-width:450px;
      padding:20px 0;
      margin:0 auto;
      justify-content: space-evenly;
      h4{
        a{
          color:#000;
          font-size: 14px;
          font-weight: bold;
          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
    .textBox{
      font-size: 12px;
      text-align: center;
      line-height: 1.8;
      a{
        color:#333;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
}
.home_video {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
}
.video_main {
  display: none;
  position: fixed;
  top: 50%;
  left: 5%;
  width: 90%;
  margin-top: -35vh;
  min-height:70vh;
  z-index: 9999;
  background: #000;
}
.video_main video {
  width: 100%;
}

@media screen and (max-width: 880px) {
  .video_main {
    width: 96%;
    left: 0;
    margin:-25vh 2% 0 2%;
    top: 50%;
    min-height: 50vh;
  }
  .home_video .video_main {
    width: 100%;
    margin-left: 0;
    left: 0;
  }
  .headerMain{
    .header{
      display: none;
    }
    .header2{
      display: none;
    }
    .header_h5{
      background: rgba(0, 101, 175, 1);
      height:60px;
      font-size: 14px;
      width:100%;
      display: block;
      position: relative;
      .h5MenuTop{
        width:100%;
        padding:0 20px;
        height:60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .rightBtn{
          .mk-css-icon-menu,.is-active{
            width: 18px;
            height: 18px;
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
            .mk-css-icon-menu-line{
              transition: all 0.15s ease-in-out;
              height: 3px;
              margin-bottom: 3px;
              border-radius: 3px;
              display: block;
              background: #fff;
              &:last-child{
                margin:0;
              }
            }
          }
          .is-active{
            .mk-css-icon-menu-line{
              position: relative;
              &:last-child{
                transform: rotate3d(0, 0, 1, -45deg);
                -webkit-transform: rotate3d(0, 0, 1, -45deg);
                top:-6px;
              }
              &:first-child{
                -webkit-transform: rotate3d(0, 0, 1, 45deg);
                transform: rotate3d(0, 0, 1, 45deg);
                top:6px;
              }
              &:nth-child(2){
                opacity: 0;
              }
            }
          }
        }
      }
      .h5MenuList{
        background: #fff;
        position: absolute;
        width: 100%;
        top:60px;
        z-index: 88888;
        display: none;
        .menuListItem{
          font-size: 14px;
          font-weight: bold;
          border-bottom:1px solid rgba(128, 128, 128, 0.2);
          cursor: pointer;
          color:#444;
          a{
            display: block;
            padding:12px 20px;
          }
          &:hover{
            background: rgba(0,0,0,.02);
          }
        }
      }
      &.active{
        position: fixed;
        top:0;
        left:0;
        z-index: 999;
      }
    }
    .header_h52{
      height:60px;
      width:100%;
      display: none;
    }
  }
  .footerMain{
    height:auto;
    .footerMenuBox{
      padding:20px 0 20px 40px;
      height:auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .menuItem{
        width:50%;
        margin:0;
        padding:0 20px 20px 0;
        &:last-child{
          margin-top:0;
        }
        &:nth-child(2),&:nth-child(4){
          padding-left:20px;
        }
      }
    }
    .footerBomBox{
      height:auto;
      padding-bottom:20px;
    }
  }
}

@media screen and (max-width: 700px) {
  .video_main {
    width: 96%;
    left: 0;
    margin:-20vh 2% 0 2%;
    top: 50%;
    min-height: 40vh;
  }
}
@media screen and (max-width: 500px) {
  .video_main {
    width: 96%;
    left: 0;
    margin:-15vh 2% 0 2%;
    top: 50%;
    min-height: 30vh;
  }
}

