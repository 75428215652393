.index_main {
  display: block;
}
* {
  -webkit-touch-callout: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: '微软雅黑', 'Microsoft YaHei', 'microsoft jhenghei', '微软正黑体', '黑体', 'simhei', 'Heiti SC Light', 'Heiti SC Medium', 'PingFang SC Medium' !important;
}
a {
  text-decoration: none;
  color: #444;
}
a:focus {
  outline: none;
  color: #444;
}
a:hover {
  text-decoration: underline;
}
body,
html,
.app {
  padding: 0;
  margin: 0;
  background: #fff;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
div {
  font-size: 12px;
}
.color-pink {
  color: #eb2f96;
}
.color-red {
  color: #f5222d;
}
.color-orange {
  color: #fa8c16;
}
.color-green {
  color: #52c41a;
}
.color-cyan {
  color: #13c2c2 !important;
}
.color-blue {
  color: #1890ff !important;
}
.color-purple {
  color: #722ed1 !important;
}
.headerMain .header {
  background: #0065af;
  height: 100px;
  font-size: 14px;
  width: 100%;
  padding: 0 20px;
}
.headerMain .header .menuMain {
  display: flex;
  justify-content: space-between;
  max-width: 1100px;
  margin: 0 auto;
  align-items: center;
  height: 100px;
}
.headerMain .header .menuMain .imgBox {
  width: 100px;
  cursor: pointer;
}
.headerMain .header .menuMain .imgBox img {
  width: 100%;
}
.headerMain .header .menuMain .menuBox {
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  height: 100px;
}
.headerMain .header .menuMain .menuBox .menuItem {
  text-align: right;
  height: 100px;
  line-height: 100px;
}
.headerMain .header .menuMain .menuBox .menuItem a {
  padding: 0 25px;
  display: block;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  /* Firefox 4 */
  -webkit-transition: all 0.2s linear;
  /* Safari 和 Chrome */
  -o-transition: all 0.2s linear;
}
.headerMain .header .menuMain .menuBox .menuItem a h3 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  display: initial;
}
.headerMain .header .menuMain .menuBox .menuItem a:hover {
  background: #beeeff;
  text-decoration: none;
}
.headerMain .header .menuMain .menuBox .menuItem a:hover h3 {
  color: #0065af;
}
.headerMain .header.active {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
}
.headerMain .header2 {
  height: 100px;
  width: 100%;
  display: none;
  background: transparent;
}
.headerMain .header_h5 {
  display: none;
}
.headerMain .toTopBtn {
  position: fixed;
  right: 15px;
  display: none;
  bottom: 30px;
  cursor: pointer;
  z-index: 998;
}
.footerMain {
  background: #0065af;
  height: 450px;
  width: 100%;
}
.footerMain .footerMenuBox {
  max-width: 1100px;
  margin: 0 auto;
  padding: 40px 20px;
  display: flex;
  height: 320px;
  justify-content: space-evenly;
}
.footerMain .footerMenuBox .menuItem {
  width: 20%;
}
.footerMain .footerMenuBox .menuItem h3 {
  width: 100%;
  margin-bottom: 15px;
  cursor: pointer;
}
.footerMain .footerMenuBox .menuItem h3 a {
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}
.footerMain .footerMenuBox .menuItem h3 a:hover {
  text-decoration: underline;
}
.footerMain .footerMenuBox .menuItem p {
  margin-bottom: 10px;
}
.footerMain .footerMenuBox .menuItem p a {
  color: #fff;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
}
.footerMain .footerMenuBox .menuItem p a:hover {
  text-decoration: underline;
}
.footerMain .footerMenuBox .menuItem:last-child {
  text-align: center;
  margin-top: 30px;
}
.footerMain .footerMenuBox .menuItem:last-child h4 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  margin-top: 10px;
}
.footerMain .footerBomBox {
  height: 130px;
  width: 100%;
  background: #dbdbdb;
}
.footerMain .footerBomBox .btnList {
  width: 100%;
  display: flex;
  max-width: 450px;
  padding: 20px 0;
  margin: 0 auto;
  justify-content: space-evenly;
}
.footerMain .footerBomBox .btnList h4 a {
  color: #000;
  font-size: 14px;
  font-weight: bold;
}
.footerMain .footerBomBox .btnList h4 a:hover {
  text-decoration: underline;
}
.footerMain .footerBomBox .textBox {
  font-size: 12px;
  text-align: center;
  line-height: 1.8;
}
.footerMain .footerBomBox .textBox a {
  color: #333;
}
.footerMain .footerBomBox .textBox a:hover {
  text-decoration: underline;
}
.home_video {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
}
.video_main {
  display: none;
  position: fixed;
  top: 50%;
  left: 5%;
  width: 90%;
  margin-top: -35vh;
  min-height: 70vh;
  z-index: 9999;
  background: #000;
}
.video_main video {
  width: 100%;
}
@media screen and (max-width: 880px) {
  .video_main {
    width: 96%;
    left: 0;
    margin: -25vh 2% 0 2%;
    top: 50%;
    min-height: 50vh;
  }
  .home_video .video_main {
    width: 100%;
    margin-left: 0;
    left: 0;
  }
  .headerMain .header {
    display: none;
  }
  .headerMain .header2 {
    display: none;
  }
  .headerMain .header_h5 {
    background: #0065af;
    height: 60px;
    font-size: 14px;
    width: 100%;
    display: block;
    position: relative;
  }
  .headerMain .header_h5 .h5MenuTop {
    width: 100%;
    padding: 0 20px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .headerMain .header_h5 .h5MenuTop .rightBtn .mk-css-icon-menu,
  .headerMain .header_h5 .h5MenuTop .rightBtn .is-active {
    width: 18px;
    height: 18px;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  .headerMain .header_h5 .h5MenuTop .rightBtn .mk-css-icon-menu .mk-css-icon-menu-line,
  .headerMain .header_h5 .h5MenuTop .rightBtn .is-active .mk-css-icon-menu-line {
    transition: all 0.15s ease-in-out;
    height: 3px;
    margin-bottom: 3px;
    border-radius: 3px;
    display: block;
    background: #fff;
  }
  .headerMain .header_h5 .h5MenuTop .rightBtn .mk-css-icon-menu .mk-css-icon-menu-line:last-child,
  .headerMain .header_h5 .h5MenuTop .rightBtn .is-active .mk-css-icon-menu-line:last-child {
    margin: 0;
  }
  .headerMain .header_h5 .h5MenuTop .rightBtn .is-active .mk-css-icon-menu-line {
    position: relative;
  }
  .headerMain .header_h5 .h5MenuTop .rightBtn .is-active .mk-css-icon-menu-line:last-child {
    transform: rotate3d(0, 0, 1, -45deg);
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    top: -6px;
  }
  .headerMain .header_h5 .h5MenuTop .rightBtn .is-active .mk-css-icon-menu-line:first-child {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    top: 6px;
  }
  .headerMain .header_h5 .h5MenuTop .rightBtn .is-active .mk-css-icon-menu-line:nth-child(2) {
    opacity: 0;
  }
  .headerMain .header_h5 .h5MenuList {
    background: #fff;
    position: absolute;
    width: 100%;
    top: 60px;
    z-index: 88888;
    display: none;
  }
  .headerMain .header_h5 .h5MenuList .menuListItem {
    font-size: 14px;
    font-weight: bold;
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
    cursor: pointer;
    color: #444;
  }
  .headerMain .header_h5 .h5MenuList .menuListItem a {
    display: block;
    padding: 12px 20px;
  }
  .headerMain .header_h5 .h5MenuList .menuListItem:hover {
    background: rgba(0, 0, 0, 0.02);
  }
  .headerMain .header_h5.active {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }
  .headerMain .header_h52 {
    height: 60px;
    width: 100%;
    display: none;
  }
  .footerMain {
    height: auto;
  }
  .footerMain .footerMenuBox {
    padding: 20px 0 20px 40px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .footerMain .footerMenuBox .menuItem {
    width: 50%;
    margin: 0;
    padding: 0 20px 20px 0;
  }
  .footerMain .footerMenuBox .menuItem:last-child {
    margin-top: 0;
  }
  .footerMain .footerMenuBox .menuItem:nth-child(2),
  .footerMain .footerMenuBox .menuItem:nth-child(4) {
    padding-left: 20px;
  }
  .footerMain .footerBomBox {
    height: auto;
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 700px) {
  .video_main {
    width: 96%;
    left: 0;
    margin: -20vh 2% 0 2%;
    top: 50%;
    min-height: 40vh;
  }
}
@media screen and (max-width: 500px) {
  .video_main {
    width: 96%;
    left: 0;
    margin: -15vh 2% 0 2%;
    top: 50%;
    min-height: 30vh;
  }
}
